import React, { lazy } from 'react';
import Loadable from "app/components/Loadable";

const UserProfile = Loadable(lazy(() => import("./UserProfile")));
const userRoutes = [
    {
        path: '/user/profile',
        element: <UserProfile />,
    }

]

export default userRoutes