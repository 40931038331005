import { setSnackbar } from './SnackbarActions';
import axios from 'axios';
import { getSiteUsers } from './SiteActions';
import { getAllUsersByOrgId } from './OrgActions';
const apiConfig = {
  domain: process.env.REACT_APP_API_DOMAIN,
  version: process.env.REACT_APP_API_VERSION,
};

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_SITES = 'GET_USER_SITES';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const getUserList = () => (dispatch) => {
  axios
    .get(apiConfig.domain + apiConfig.version + '/GetUsers')
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setSnackbar('error', 'Unable to retrieve user info'));
      console.log(err.message);
    });
};

export const getUserById = (id) => (dispatch) => {
  axios
    .get(apiConfig.domain + apiConfig.version + '/GetUserById', {
      params: { Id: id }, //Add userID as a param
    })
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch(setSnackbar('error', 'Unable to retrieve user info'));
      console.log(error);
    });
};

export const getUserByUserId = () => (dispatch) => {
  axios
    .get(apiConfig.domain + apiConfig.version + '/GetUserByUserId')
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch(setSnackbar('error', 'Unable to retrieve user info'));
      console.log(error);
    });
};

export const getUserByEmail = (email) => (dispatch) => {
  axios
    .get(apiConfig.domain + apiConfig.version + '/GetUserByEmail', {
      params: { Email: email }, //Add userID as a param
    })
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch(setSnackbar('error', 'Unable to retrieve user info'));
      console.log(error);
    });
};

// export const getUserSites = (userId) => (dispatch) => {
//   axios
//     .get(apiConfig.domain + apiConfig.version + "/GetUserSitesByUserId", {
//       params: { UserId: userId }
//     })
//     .then((res) => {
//       dispatch({
//         type: GET_USER_SITES,
//         payload: res.data
//       });
//     })
//     .catch((error) => {
//       dispatch(setSnackbar("error", "Unable to retrieve Projects info"));
//       console.log(error);
//     });
// };

export const getUserSites = (userId) => (dispatch) => {
  dispatch({ type: 'GET_USER_SITES_REQUEST' });
  return axios
    .get(apiConfig.domain + apiConfig.version + '/GetUserSitesByUserId', {
      params: { UserId: userId },
    })
    .then((res) => {
      dispatch({
        type: 'GET_USER_SITES_SUCCESS',
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({ type: 'GET_USER_SITES_FAILURE' });
      dispatch(setSnackbar('error', 'Unable to retrieve Projects info'));
      console.log(error);
    });
};

export const getUserSitesById = (Id) => (dispatch) => {
  axios
    .get(apiConfig.domain + apiConfig.version + '/GetUserSitesById', {
      params: { Id: Id }, //Add userID as a param
    })
    .then((res) => {
      dispatch({
        type: GET_USER_SITES,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch(setSnackbar('error', 'Unable to retrieve Projects info'));
      console.log(error);
    });
};

export const createUser =
  ({ email }) =>
  async (dispatch) => {
    const user = {
      ...(email && { email }),
    };
    try {
      const res = await axios.put(
        apiConfig.domain + apiConfig.version + '/CreateUser',
        user,
      );
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      });

      dispatch(setSnackbar('success', 'User created successfully'));
    } catch (error) {
      dispatch(setSnackbar('error', 'Unable to create user'));
      console.log(error.message);
    }
  };
export const updateUser =
  ({
    id,
    userId,
    tenantId,
    email,
    firstName,
    middleName,
    lastName,
    isActive,
    phone,
    address,
    city,
    state,
    country,
    zipCode,
    userRole,
    photoUrl,
  }) =>
  (dispatch) => {
    const user = {
      ...(id && { id }),
      ...(userId && { userId }),
      ...(tenantId && { tenantId }),
      ...(email && { email }),
      ...(firstName && { firstName }),
      ...(middleName && { middleName }),
      ...(lastName && { lastName }),
      ...(isActive && { isActive }),
      ...(phone && { phone }),
      ...(address && { address }),
      ...(city && { city }),
      ...(state && { state }),
      ...(country && { country }),
      ...(zipCode && { zipCode }),
      ...(userRole && { userRole }),
      ...(photoUrl && { photoUrl }),
    };
    axios
      .put(apiConfig.domain + apiConfig.version + '/UpdateUser', user, {
        params: { Id: id, UserId: userId, Email: email }, //Add userID as a param
      })
      .then((res) => {
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
        dispatch(setSnackbar('success', 'User updated successfully'));
      })
      .catch((error) => {
        dispatch(setSnackbar('error', 'Unable to update user'));
        console.log(error.message);
      });
  };

export const deactivateUser = (userEmail) => (dispatch) => {
  axios
    .delete(apiConfig.domain + apiConfig.version + '/DeleteUser', {
      params: { Email: userEmail }, //Add userEmail as a param
    })
    .then((res) => {
      dispatch({
        type: DELETE_USER,
        payload: res.data,
      });
      dispatch(getUserList());
    })
    .catch((error) => {
      dispatch(setSnackbar('error', 'Unable to deactivate user'));
      console.log(error);
    });
};

export const reactivateUser = (userEmail) => (dispatch) => {
  axios
    .put(apiConfig.domain + apiConfig.version + '/ReactivateUser', null, {
      params: { Email: userEmail }, //Add userEmail as a param
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(getUserList());
    })
    .catch((error) => {
      dispatch(setSnackbar('error', 'Unable to activate user'));
      console.log(error.message);
    });
};

//Org/User Actions these might need to be moved to user actions

// Action types
export const GET_ALL_ORGS_BY_USER_ID = 'GET_ALL_ORGS_BY_USER_ID';
export const GET_ALL_USERS_BY_ORG_ID = 'GET_ALL_USERS_BY_ORG_ID';
export const INVITE_USER_TO_ORG = 'INVITE_USER_TO_ORG';
export const REMOVE_USER_FROM_ORG = 'REMOVE_USER_FROM_ORG';
export const UPDATE_USER_ORG_ROLE = 'UPDATE_USER_ORG_ROLE';
export const GET_ORG_INFO_BY_ORGID = 'GET_ORG_INFO_BY_ORGID';

// Action creators
export const getAllOrgsByUserId = (userId) => async (dispatch) => {
  // Implement API call and dispatch action
  const url = `${apiConfig.domain}${apiConfig.version}/GetAllOrgsByUserId`;
  try {
    const response = await axios.get(url, { params: { userId } });
    dispatch({
      type: GET_ALL_ORGS_BY_USER_ID,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getOrgInfoById = (orgId) => async (dispatch) => {
  const url = `${apiConfig.domain}${apiConfig.version}/GetOrgById`;
  try {
    const response = await axios.get(url, { params: { orgId } });
    if (response.data !== null) {
      dispatch({
        type: GET_ORG_INFO_BY_ORGID,
        payload: [response.data],
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const inviteUserToOrg = (orgId, newOrgUsers) => async (dispatch) => {
  const url = `${apiConfig.domain}${apiConfig.version}/InviteUserToOrg`;
  try {
    const response = await axios.post(url, newOrgUsers, {
      params: { orgId },
    });
    if (response.status === 200) {
      dispatch({
        type: INVITE_USER_TO_ORG,
        payload: response.data,
      });
      dispatch(getAllUsersByOrgId(orgId));
      dispatch(setSnackbar('success', 'User Added to Organization'));
    } else {
      dispatch(setSnackbar('error', 'Failed to update Organization role'));
    }
  } catch (error) {
    dispatch(setSnackbar('error', `Received status code ${error.message}`));
  }
};

export const removeUserFromOrg = (orgId, userEmail) => async (dispatch) => {
  const url = `${apiConfig.domain}${apiConfig.version}/RemoveUserFromOrg`;
  try {
    const response = await axios.put(url, null, {
      params: { orgId, userEmail },
    });

    dispatch({
      type: REMOVE_USER_FROM_ORG,
      payload: response.data,
    });
    if (response.status === 200) {
      dispatch(getAllUsersByOrgId(orgId));
      dispatch(setSnackbar('success', 'User removed successfully'));
    } else {
      dispatch(setSnackbar('error', 'Failed to remove user'));
    }
  } catch (error) {
    dispatch(setSnackbar('error', `Received status code ${error}`));
  }
};

export const updateUserOrgRole =
  (orgId, useremail, OrgRoleId) => async (dispatch) => {
    const url = `${apiConfig.domain}${apiConfig.version}/UpdateUserOrgRole`;
    try {
      const response = await axios.put(url, null, {
        params: { orgId, useremail, OrgRoleId },
      });

      dispatch({
        type: UPDATE_USER_ORG_ROLE,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch(getAllUsersByOrgId(orgId));
        dispatch(setSnackbar('success', 'User role updated successfully'));
      } else {
        dispatch(setSnackbar('error', 'Failed to update user role'));
      }
    } catch (error) {
      dispatch(setSnackbar('error', `Received status code ${error}`));
    }
  };

export const updateUserSiteRole =
  (siteId, email, siteRoleContent) => async (dispatch) => {
    try {
      const res = await axios.put(
        `${apiConfig.domain}${apiConfig.version}/UpdateUserSiteRole`,
        siteRoleContent,
        {
          params: { SiteId: siteId, UserEmail: email },
        },
      );
      if (res.status === 200) {
        dispatch(getSiteUsers({ siteId: siteId }));
        dispatch(setSnackbar('success', 'User role updated successfully'));
      } else {
        dispatch(setSnackbar('error', 'Failed to update user role'));
      }
    } catch (error) {
      dispatch(setSnackbar('error', `Received status code ${error}`));
    }
  };

export const removeUserFromSite =
  (siteId, userToDelete) => async (dispatch) => {
    try {
      const res = await axios.delete(
        `${apiConfig.domain}${apiConfig.version}/RemoveUserFromSite`,
        {
          params: {
            SiteId: siteId,
            UserEmail: userToDelete,
          },
        },
      );
      if (res.status === 200) {
        dispatch(getSiteUsers({ siteId: siteId }));
        dispatch(setSnackbar('success', 'User removed successfully'));
      } else {
        dispatch(setSnackbar('error', 'Failed to remove user'));
      }
    } catch (error) {
      dispatch(setSnackbar('error', `Received status code ${error}`));
    }
  };

export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';

export const updateUserAvatar = (photo) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('file', photo);
    const res = await axios.post(
      `${apiConfig.domain}${apiConfig.version}/UploadUserPhoto`,
      formData,
    );
    dispatch({
      type: UPDATE_USER_AVATAR,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
export const CLEAR_USER_SITES = 'CLEAR_USER_SITES';
export const CLEAR_USER_ORGS = 'CLEAR_USER_ORGS';

export const clearUserList = () => {
  return {
    type: CLEAR_USER_LIST,
  };
};

export const clearUserSites = () => {
  return {
    type: CLEAR_USER_SITES,
  };
};

export const clearUserOrgs = () => {
  return {
    type: CLEAR_USER_ORGS,
  };
};
