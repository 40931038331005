import { authRoles } from './auth/authRoles'

export const navigations = [

    {
        name: 'User Profile',
        icon: 'account_circle',
        path: '/user/profile',
        auth: authRoles.user,
    }
    ,
    {
        name: 'Dashboard',
        path: '/',
        icon: 'home',
        auth: authRoles.user,
    },
    {
        label: 'Resources',
        type: 'label',
        auth: authRoles.user,
    },
    {
        name: 'Knowledge Base',
        icon: 'launch',
        type: 'extLink',
        path: 'https://argylebuild.notion.site/50573beac8d14e8fbd3db103b6294b87?v=abfecdf2169a458e8f75dff73bde777f&pvs=4',
        auth: authRoles.user,
    },

    {
        name: 'Revit & Navisworks Plugin',
        icon: 'extension',
        type: 'extLink',
        path: 'https://www.argyle.build/download',
        auth: authRoles.user,
    },
    {
        name: 'Get the AR App',
        icon: 'star',
        type: 'extLink',
        path: 'https://argylebuild.notion.site/Get-the-AR-App-8fec21f155ee4c19b1321dd8381a8800',
        auth: authRoles.user,
    },

    {
        label: 'Management',
        type: 'label',
        auth: authRoles.admin,
    }, {
        name: 'Admin',
        children: [
            {
                name: 'Organizations',
                path: '/admin/manage-orgs',
                icon: 'group_work',
                auth: authRoles.admin,
            },
            {
                name: 'Projects',
                path: '/admin/manage-sites',
                icon: 'dashboard',
                auth: authRoles.admin,
            },
            {
                name: 'Users',
                path: '/admin/manage-users',
                auth: authRoles.admin,
                icon: 'person',
            }
        ],
    },

]