import {
    GET_ALL_USERS,
    GET_USER,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    GET_ALL_ORGS_BY_USER_ID,
    INVITE_USER_TO_ORG,
    REMOVE_USER_FROM_ORG,
    UPDATE_USER_ORG_ROLE,
    CLEAR_USER_LIST,
    CLEAR_USER_ORGS,
    CLEAR_USER_SITES,
    UPDATE_USER_AVATAR
} from '../actions/UserActions';

const initialState = {
    user: {},
    userList: [],
    userSites: [],
    userOrgs: [],
    message: '',
    uploadInfo: {},
    loading: false,
    error: null,
}

const UserReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_USERS: {
            return {
                ...state,
                userList: [...action.payload],
            }
        }
        case GET_USER: {
            return {
                ...state,
                user: { ...action.payload },
            }
        }

        case 'GET_USER_SITES_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_USER_SITES_SUCCESS':
            return {
                ...state,
                userSites: action.payload,
                loading: false,
            };
        case 'GET_USER_SITES_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CREATE_USER: {
            return {
                ...state,
                user: { ...action.payload },
            }
        }
        case UPDATE_USER: {
            return {
                ...state,
                message: action.payload,
            }
        }
        case DELETE_USER: {
            return {
                ...state,
                user: { ...action.payload },
            }
        }

        case GET_ALL_ORGS_BY_USER_ID: {
            return {
                ...state,
                userOrgs: [...action.payload],
            }
        }
        case INVITE_USER_TO_ORG: {
            return {
                ...state,
                message: action.payload,
            }
        }
        case REMOVE_USER_FROM_ORG: {
            return {
                ...state,
                message: action.payload,
            }
        }
        case UPDATE_USER_ORG_ROLE: {
            return {
                ...state,
                message: action.payload,
            }
        }
        case UPDATE_USER_AVATAR: {
            return {
                ...state,
                uploadInfo: { ...action.payload },
            }
        }
        case CLEAR_USER_LIST:
            return {
                ...state,
                userList: []
            }
        case CLEAR_USER_ORGS:
            return {
                ...state,
                userOrgs: []
            }
        case CLEAR_USER_SITES:
            return {
                ...state,
                userSites: []
            }


        default: {
            return {
                ...state,
            }
        }
    }
}

export default UserReducer
